








































import { Vue, Component, Prop } from "vue-property-decorator";
import FancyButton from "@/components/FancyButton.vue";
import { Locations, Auth } from "@/store/modules";
import { Location } from '../store/locations';

@Component({
  components: {
    FancyButton
  },
  name: 'Locations'
})
export default class Home extends Vue {
  @Prop({ required: true }) type!: 'vendors' | 'dropSites';
  hasContainers = false;
  showBottom = this.$route.query.showBottom ? this.$route.query.showBottom : false;

  get defaultLocation() {
    return Locations.defaultLocation;
  }

  get locations() {
    return Locations[this.type] || [];
  }

  get selectedLocationSlug() {
    return this.$route.query.id;
  }

  get selectedLocation() {
    return this.locations.find(location => location.slug === this.selectedLocationSlug);
  }

  getLocationIcon(location: Location) {
    if (location.isVendor && location.isReturnStation) {
      return '/both.png'
    }

    if (location.isReturnStation) {
      return '/return.png'
    }

    if (location.isVendor) {
      return '/vendor.png'
    }
  }

  onLocationClick(location: any) {
    this.$router.push({ path: this.$route.path, query: { ...this.$route.query, id: location.slug }});
    this.showBottom = true;
  }

  async mounted() {
    await this.refresh();
  }

  async beforeMount() {
    await this.refresh();
  }

  async refresh() {
    await Locations.fetchAll();
    const { containers } = await Auth.myContainers();
    this.hasContainers = Object.keys(containers).length === 0 ? false : !Object.keys(containers).every(key => containers[key] === 0);
    this.$forceUpdate();
  }

  hasReusables(location: Location) {
    if (!location.containers || !location.containers.length) {
      return false;
    }

    return !location.containers.every((container: any) => container.availableCount === 0);
  }

  takeContainers() {
    this.$router.push({ path: '/take-container', query: { vendor: this.selectedLocationSlug }});
  }

  returnContainers() {
    this.$router.push({ path: '/return-container', query: { returnStation: this.selectedLocationSlug }});
  }
}
